<template>
	<Fragment>
		<div class="wrap_row">
			<div class="row">
				<label class="form_tit">
					<span class="txt_large">회사 정보</span>
				</label>
				<div class="form_data">
					<div
						class="row"
						:class="[
							companyInfoValidClass.corporateName === 'valid' || companyInfoValidClass.corporateName === ''
								? 'valid'
								: 'error',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.corporateName"
								@input="[validSpecialInput($event)]"
							/>
							<label for="target">상호명</label>
						</div>
						<span class="com_help">
							<span class="ic_set ic_info">
								<span class="tool_tip right">
									사업자등록증에 표기되어 있는 상호명을 입력해주세요.<br />
									<i class="tail_right"></i
								></span>
							</span>
						</span>
						<span
							v-if="companyInfoValidClass.corporateName !== 'valid' && companyInfoValidClass.corporateName.length > 0"
							class="sys_msg"
							>{{
								companyInfoValidClass.corporateName === 'invalid'
									? '상호명에 | \\ " # $ &lt; &gt; , ; 특수문자는 사용 불가합니다.'
									: companyInfoValidClass.corporateName === 'error'
									? '상호명은 2자 이상, 64자 이하를 입력해야 합니다.'
									: '상호명은 필수 입력 항목입니다.'
							}}
						</span>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.ceoName === 'error' || companyInfoValidClass.ceoName === 'empty'
								? 'error'
								: companyInfoValidClass.ceoName === 'valid'
								? 'valid'
								: '',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.ceoName"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.ceoName = 'valid')
											: (companyInfoValidClass.ceoName = 'error'),
									]
								"
							/>
							<label for="target">대표자명</label>
						</div>
						<span v-if="companyInfoValidClass.ceoName === 'error'" class="sys_msg"
							>대표자명은 2자 이상, 64자 이하를 입력해야 합니다.</span
						>
						<span v-if="companyInfoValidClass.ceoName === 'empty'" class="sys_msg"
							>대표자명은 필수 입력 항목입니다.</span
						>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.businessLicenseNum === 'error' ||
							companyInfoValidClass.businessLicenseNum === 'empty'
								? 'error'
								: companyInfoValidClass.businessLicenseNum === 'valid'
								? 'valid'
								: '',
							isBusinessActive ? activeClass : '',
						]"
					>
						<div class="cell cell_confirm_registration">
							<div class="cell_grid">
								<div class="com_ipt_floating">
									<input
										class="asterisk"
										type="text"
										placeholder="."
										v-model.trim="companyInfo.businessLicenseNum"
										v-doms-inputValidate:onlyNumber=""
										@keydown="changeBussinessInfo"
										maxlength="10"
									/>
									<label for="target">사업자등록번호 (숫자만 입력해주세요.)</label>
								</div>
							</div>
							<span class="com_help">
								<span class="ic_set ic_info">
									<span class="tool_tip right">
										잘못된 사업자 번호 입력시 경리회계 개설이 불가능합니다.<br />
										<i class="tail_right"></i
									></span>
								</span>
							</span>
							<div class="cell_grid" @mouseover="isBusinessActive = true" @mouseleave="isBusinessActive = false">
								<LicenseConfirm
									v-model="businessInfoConfirm"
									:businessLicenseNumber="companyInfo.businessLicenseNum"
								></LicenseConfirm>
							</div>
						</div>
						<span v-if="companyInfoValidClass.businessLicenseNum === 'valid'" class="sys_msg">확인되었습니다. </span>
						<span v-if="companyInfoValidClass.businessLicenseNum === 'error'" class="sys_msg"
							>번호 형식이 올바르지 않습니다. (-, +, 0~9)</span
						>
						<span v-if="companyInfoValidClass.businessLicenseNum === 'empty'" class="sys_msg"
							>사업자등록번호는 필수 입력 항목입니다.</span
						>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.businessCondition === 'error' || companyInfoValidClass.businessCondition == 'empty'
								? 'error'
								: companyInfoValidClass.businessCondition === 'valid'
								? 'valid'
								: '',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.businessCondition"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.businessCondition = 'valid')
											: (companyInfoValidClass.businessCondition = 'error'),
									]
								"
							/>
							<label for="target">업태</label>
						</div>
						<span v-if="companyInfoValidClass.businessCondition === 'error'" class="sys_msg"
							>업태는 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
						<span v-if="companyInfoValidClass.businessCondition === 'empty'" class="sys_msg">
							업태는 필수입력 항목입니다.
						</span>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.businessType === 'error' || companyInfoValidClass.businessType === 'empty'
								? 'error'
								: companyInfoValidClass.businessType === 'valid'
								? 'valid'
								: '',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.businessType"
								@input="
									[
										checkInputLength($event, 2, 64)
											? (companyInfoValidClass.businessType = 'valid')
											: (companyInfoValidClass.businessType = 'error'),
									]
								"
							/>
							<label for="target">종목</label>
						</div>
						<span v-if="companyInfoValidClass.businessType === 'error'" class="sys_msg"
							>종목은 2자 이상, 64자 이하를 입력해야 합니다.
						</span>
						<span v-if="companyInfoValidClass.businessType === 'empty'" class="sys_msg">
							종목은 필수입력 항목입니다.
						</span>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.companyAddress === 'error' || companyInfoValidClass.companyAddress === 'empty'
								? 'error'
								: companyInfoValidClass.companyAddress === 'valid'
								? 'valid'
								: '',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								type="text"
								placeholder="."
								v-model="companyInfo.companyAddress"
								@input="
									[
										checkInputLength($event, 0, 5000)
											? (companyInfoValidClass.companyAddress = 'valid')
											: (companyInfoValidClass.companyAddress = 'error'),
									]
								"
							/>
							<label for="target">사업장 주소</label>
						</div>
						<div class="com_ipt_floating"></div>
						<span
							v-if="
								companyInfoValidClass.companyAddress === 'error' || companyInfoValidClass.companyAddress === 'empty'
							"
							class="sys_msg"
							>사업장 주소는 필수입력 항목입니다.</span
						>
					</div>
					<div
						class="row"
						:class="[
							companyInfoValidClass.employeeCount === 'error' || companyInfoValidClass.employeeCount === 'empty'
								? 'error'
								: companyInfoValidClass.employeeCount === 'valid'
								? 'valid'
								: '',
						]"
					>
						<div class="com_ipt_floating">
							<input
								class="asterisk"
								min="0"
								v-model="companyInfo.employeeCount"
								type="text"
								placeholder="."
								v-doms-inputValidate:onlyNumber=""
								@keyup="
									[
										!companyInfo.employeeCount || companyInfo.employeeCount < 1 || companyInfo.employeeCount > 99999
											? (companyInfoValidClass.employeeCount = 'error')
											: (companyInfoValidClass.employeeCount = 'valid'),
									]
								"
							/>
							<label for="target">실 임직원 수</label>
						</div>
						<span v-if="companyInfoValidClass.employeeCount === 'error'" class="sys_msg"
							>임직원 수는 1 이상, 99999 이하를 입력해야 합니다.</span
						>
						<span v-if="companyInfoValidClass.employeeCount === 'empty'" class="sys_msg"
							>임직원 수는 필수 입력 항목입니다.</span
						>
					</div>
				</div>
			</div>
		</div>
		<div class="wrap_row">
			<div class="row">
				<label class="form_tit">
					<span class="txt_large">사업자등록증</span>
				</label>
				<div class="form_data">
					<div class="row">
						<AttachComponent :gatherFlag="gatherFlag"></AttachComponent>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment';
import { validation } from '@/components/apply/mixinsValidation.js';
import AttachComponent from '@/components/attach/Attach';
import LicenseConfirm from '@/components/businessLicense/LicenseConfirm';
import { validateOrderSpecialChar } from '@/utils/utils';

export default {
	mixins: [validation],
	components: {
		AttachComponent,
		Fragment,
		LicenseConfirm,
	},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		const origin = this.$store.getters.getAllCompanyInfo.companyInfo;
		return {
			companyInfo: {
				corporateName: origin.corporateName ? origin.corporateName : '', // 필수
				businessLicenseNum: origin.businessLicenseNum ? origin.businessLicenseNum : '', // 필수
				ceoName: origin.ceoName ? origin.ceoName : '', // 필수
				directPhone: origin.directPhone ? origin.directPhone : '',
				companyZipCode: origin.companyZipCode ? origin.companyZipCode : '',
				companyAddress: origin.companyAddress ? origin.companyAddress : '', // 필수
				industrySector: origin.industrySector ? origin.industrySector : '', // 필수
				businessCondition: origin.businessCondition ? origin.businessCondition : '', //필수
				businessType: origin.businessType ? origin.businessType : '', //필수
				confirm: origin.businessLicenseNum ? true : false,
				employeeCount: origin.employeeCount ? origin.employeeCount : '',
			},
			companyInfoValidClass: {
				corporateName: origin.corporateName ? 'valid' : '',
				ceoName: origin.ceoName ? 'valid' : '',
				businessLicenseNum: origin.businessLicenseNum ? 'valid' : '',
				directPhone: 'valid',
				industrySector: 'valid',
				companyZipCode: 'valid',
				companyAddress: origin.companyAddress ? 'valid' : '',
				businessCondition: origin.businessCondition ? 'valid' : '',
				businessType: origin.businessType ? 'valid' : '',
				employeeCount: origin.employeeCount ? 'valid' : '',
			},
			businessInfoConfirm: origin.businessLicenseNum ? true : false,
			activeClass: 'active',
			isBusinessActive: false,
		};
	},
	computed: {
		companyNameErrorMessage() {
			if (this.companyInfoValidClass.corporateName === 'empty') {
				return '상호명은 필수 입력 항목입니다.';
			} else if (this.companyInfoValidClass.corporateName === 'invalid') {
				return `상호명에 | \\ " # $ < > , ; 특수문자는 사용 불가합니다.`;
			} else if (this.companyInfoValidClass.corporateName === 'error') {
				return '상호명은 2자 이상, 64자 이하를 입력해야 합니다.';
			}
			return '';
		},
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
		businessInfoConfirm() {
			this.companyInfo.confirm = this.businessInfoConfirm;
			if (this.businessInfoConfirm) {
				this.companyInfoValidClass.businessLicenseNum = 'valid';
			} else {
				this.companyInfoValidClass.businessLicenseNum = '';
			}
		},
		'companyInfo.employeeCount'() {
			if (isNaN(this.companyInfo.employeeCount)) {
				this.companyInfo.employeeCount = '';
			}

			if (
				Number.parseInt(this.companyInfo.employeeCount) < 1 ||
				Number.parseInt(this.companyInfo.employeeCount) > 99999
			) {
				this.companyInfo.employeeCount = '';
			}
		},
	},
	methods: {
		validSpecialInput(event) {
			if (this.checkSpecialChar(event)) {
				this.companyInfoValidClass.corporateName = 'invalid';
			} else if (event.target.value.trim().length === 0) {
				this.companyInfoValidClass.corporateName = 'empty';
			} else if (!this.checkInputLength(event, 2, 64)) {
				this.companyInfoValidClass.corporateName = 'error';
			} else {
				this.companyInfoValidClass.corporateName = 'valid';
			}
		},
		changeBussinessInfo() {
			this.businessInfoConfirm = false;
			this.companyInfo.confirm = false;
			this.companyInfoValidClass.businessLicenseNum = '';
		},
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyInfo)) {
				const key = Object.keys(this.companyInfo)[idx];
				const value = this.companyInfo[key];
				if (
					key === 'corporateName' ||
					key === 'businessLicenseNum' ||
					key === 'ceoName' ||
					key === 'companyAddress' ||
					key === 'businessCondition' ||
					key === 'businessType' ||
					key === 'employeeCount'
				) {
					if (!value || value.length === 0) {
						this.companyInfoValidClass[key] = 'empty';
					}
					if (this.companyInfoValidClass[key] !== 'valid') {
						isAllValid = false;
					}
				}
			}

			return isAllValid;
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyInfo',
				companyInfo: this.companyInfo,
				companyInfoValidClass: this.companyInfoValidClass,
			});
		},
	},
};
</script>
