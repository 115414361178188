<template>
	<div class="wrap_row">
		<div class="row">
			<label v-if="useLabel" class="form_tit cell">
				<span class="txt">첨부파일</span>
			</label>
			<div class="form_data cell">
				<button class="general" @click="openFileTypeInput" v-if="isEdit">
					<i class="ic_set ic_file"></i>
					<label>사업자 등록증 첨부</label>
					<input type="file" ref="fileInput" :id="inputId" v-on:change="selectFile($event)" style="display: none" />
				</button>
				<span class="com_help file_upload_help" v-if="isEdit">
					<span class="ic_set ic_info">
						<span class="tool_tip left">
							세금계산서 발급을 원하시거나 경리회계서비스 이용을 원하실 경우 반드시 사업자 등록증을 첨부해주시기
							바랍니다.<br />
							<i class="tail_right"></i
						></span>
					</span>
				</span>
				<ul class="com_file">
					<li v-for="attach in attachList" :key="attach.attachKey">
						<!-- 	<a class="txt" v-on:click="getFile(attach.attachKey)">-->
						<span>{{ attach.originFilename }}</span>
						<button class="del" title="삭제" v-on:click="deleteFile(attach.attachKey)" v-if="isEdit">
							<i class="ic_set ic_del"></i>
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { fileUpload, fileApply, getFile } from '@/api/common';
export default {
	props: {
		value: {
			type: Array,
			default: () => [],
			description: 'attaches',
		},
		useLabel: {
			type: Boolean,
			default: false,
		},
		validation: {
			type: Object,
			default() {
				return {};
			},
		},
		multiSelect: {
			type: Boolean,
			default: false,
		},
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			attaches: this.value,
			inputId: this._uid + 'domsupload',
		};
	},
	mounted() {
		this.attaches = this.attaches.filter((attach) => attach.id != '');
		const origin = this.$store.getters.getBusinessRegistration;
		if (origin) {
			this.attaches = origin;
		}
	},
	watch: {
		attaches() {
			this.$emit('input', this.attaches);
		},
		gatherFlag() {
			if (this.gatherFlag) {
				this.sendValidInfo();
			}
		},
	},
	computed: {
		attachList() {
			if (!this.attaches) return;
			return this.attaches.filter((attach) => attach.deletedAt == undefined || attach.deletedAt == null);
		},
	},

	methods: {
		async getFile(fileId) {
			try {
				console.log(fileId);
				await getFile(fileId);
			} catch (e) {
				console.log(e);
			}
		},
		selectFile(event) {
			if (this.validate(event.target.files[0])) {
				this.upload(event.target.files);
			}
		},
		openFileTypeInput() {
			this.$refs.fileInput.click();
		},
		async upload(file) {
			try {
				var formData = new FormData();
				formData.append('file', file[0]);
				const { data } = await fileUpload(formData);
				const fileInfo = await this.apply(data);
				if (!this.multiSelect) {
					this.attaches = [fileInfo];
				} else {
					this.attaches.push(fileInfo);
				}
				if (this.attaches.length > 0) {
					this.$store.commit('setBusinessRegistration', this.attaches);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async apply(file) {
			try {
				const { data } = await fileApply(file);
				const fileInfo = {
					attachKey: data.attachKey,
					originFilename: data.originFilename,
					path: data.filePath,
					deletedAt: null,
					extension: data.extension,
					size: data.size,
				};

				return fileInfo;
			} catch (e) {
				console.log(e);
			}
		},
		deleteFile(fileId) {
			this.attaches = this.attaches.filter((attach) => {
				attach.attachKey !== fileId;
			});
		},
		validate(file) {
			// name check
			if (this.validation.name != undefined && !file?.name.includes(this.validation.name)) {
				this.$ms.dialog.alert('파일 명 에러', `잘못된 파일명 입니다. "${this.validation.name}" 를 포함시켜 주세요.`);
				return false;
			}
			// 필요하면 용량, 확장자 등등
			return true;
		},
		sendValidInfo() {
			if (this.attaches.length > 0) {
				this.$store.commit('setBusinessRegistration', this.attaches);
			}
		},
	},
};
</script>

<style scoped>
.file_upload_help {
	right: initial !important;
	top: 3px !important;
}
</style>
