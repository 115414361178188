<template>
	<div class="wrap_function">
		<a v-if="!isFirstPage" class="minor large" @click="movePrevStep" :disabled="disable">
			{{ stepBtnInfo.prev ? stepBtnInfo.prev : '이전' }}
		</a>
		<a
			v-if="!isLastPage"
			:class="[disable ? 'minor large' : 'major large']"
			@click="onClickNextBtn"
			:disabled="disable"
		>
			{{ stepBtnInfo.next ? stepBtnInfo.next : '다음' }}
		</a>
	</div>
</template>

<script>
export default {
	props: {
		disable: {
			type: Boolean,
			default: false,
		},
		stepBtnInfo: {
			type: Object,
			default: () => {
				return {
					prev: '이전',
					next: '다음',
				};
			},
			required: true,
			description: 'prev|prevLink|next|nextLink',
		},
		actionBeforeMove: {
			type: Function,
			default: null,
			description: 'return true or false',
		},
		isFirstPage: {
			type: Boolean,
			default: false,
		},
		isLastPage: {
			type: Boolean,
			default: false,
		},
		isNextClose: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		movePrevStep() {
			if (!this.disable) {
				this.$router.push(this.stepBtnInfo.prevLink);
			}
		},

		onClickNextBtn() {
			if (!this.actionBeforeMove || !this.actionBeforeMove()) {
				return;
			}
			this.moveNextStep();
		},
		moveNextStep() {
			if (!this.disable) {
				if (this.isNextClose) {
					this.$router.push(`/apply/step1`);
					return false;
				}
				if (this.stepBtnInfo.nextLink) {
					this.$router.push(this.stepBtnInfo.nextLink);
				}
			}
		},
	},
};
</script>

<style></style>
