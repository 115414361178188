<template>
	<div>
		<div class="section_body section_join1">
			<!--layer : layer기본틀 : 토스트레이어 : 사용자의 액션에 대한 피드백을 위함. 일정 시간 후 사라짐-->

			<div class="section_content">
				<div class="com_stepper">
					<ul>
						<li class="current"><i>step1</i></li>
						<li><i>step2</i></li>
						<li><i>step3</i></li>
					</ul>
				</div>

				<div class="com_form_large form_join1">
					<!--@--회사정보-->
					<company-basic-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-basic-info>

					<!--@--담당자 정보-->
					<company-manager-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-manager-info>

					<!--@--개설 정보-->
					<company-site-info :gatherFlag="gatherFlag" v-on:gatherData="gatherData"></company-site-info>
				</div>

				<step-footer :stepBtnInfo="stepBtnInfo" :actionBeforeMove="gatherAllData"></step-footer>
			</div>
		</div>
	</div>
</template>

<script>
import StepFooter from '@/components/common/StepFooter.vue';
import CompanyBasicInfo from '@/components/apply/serviceApplyInfo/CompanyBasicInfo.vue';
import CompanyManagerInfo from '@/components/apply/serviceApplyInfo/CompanyManagerInfo.vue';
import CompanySiteInfo from '@/components/apply/serviceApplyInfo/CompanySiteInfo.vue';
import ToastComponent from '@/components/code/modal/ToastComponent.vue';

export default {
	components: {
		CompanyBasicInfo,
		CompanyManagerInfo,
		CompanySiteInfo,
		StepFooter,
	},
	data() {
		return {
			toastOpen: false,
			toastText: '',
			gatherFlag: false,
			saveFlag: false,
			stepBtnInfo: {
				prevLink: '/apply/step1',
				nextLink: '/apply/service/step2',
			},
			gatheredValid: {
				companyInfoValidClass: null,
				companyManagerInfoValidClass: null,
				companySiteInfoValidClass: null,
				//companyAdditionalInfoValidClass: null,
				confirm: null,
			},
			gatheredData: {
				companyInfo: null,
				companyManagerInfo: null,
				companySiteInfo: null,
				//companyAdditionalInfo: null,
			},
		};
	},
	watch: {
		saveFlag() {
			if (this.saveFlag) {
				this.$router.push('/apply/service/step2');
			}
		},
	},
	methods: {
		openToast(text) {
			this.$modal.show(
				ToastComponent,
				{
					toastText: text,
				},
				{
					width: '800px',
					height: '80px',
					draggable: true,
				},
			);
		},
		validate() {
			return true;
		},
		async saveAllInfo() {
			await this.$store.commit('setAllCompanyInfo', this.gatheredData);
			this.saveFlag = true;
		},
		gatherAllData() {
			this.gatherFlag = true;
		},
		async resetGatherFlag(data) {
			this.gatherFlag = false;
		},
		async gatherData(data) {
			if (data.type == 'companyInfo') {
				this.gatheredData.companyInfo = data.companyInfo;
				this.gatheredValid.companyInfoValidClass = data.companyInfoValidClass;
				if (!data.companyInfo.confirm) {
					this.openToast('사업자 진위확인은 필수 입니다.');
					this.gatheredValid.confirm = null;
					this.resetGatherFlag();
					window.scrollTo(0, 0);
				} else {
					this.gatheredValid.confirm = true;
				}
			} else if (data.type == 'companyManagerInfo') {
				this.gatheredData.companyManagerInfo = data.companyManagerInfo;
				this.gatheredValid.companyManagerInfoValidClass = data.companyManagerInfoValidClass;
			} else if (data.type == 'companySiteInfo') {
				this.gatheredData.companySiteInfo = data.companySiteInfo;
				this.gatheredValid.companySiteInfoValidClass = data.companySiteInfoValidClass;
			} /*else if (data.type == 'companyAdditionalInfo') {
				this.gatheredData.companyAdditionalInfo = data.companyAdditionalInfo;
				this.gatheredValid.companyAdditionalInfoValidClass =
					data.companyAdditionalInfoValidClass;
			}*/

			for (const [key, value] of Object.entries(this.gatheredValid)) {
				if (value == null || value == undefined) {
					return false;
				}
			}

			return this.checkValid(this.gatheredValid);
		},
		async checkValid(validData) {
			// 회사정보
			this.gatherFlag = false;
			for (const [name, data] of Object.entries(validData)) {
				if (name == 'companySiteInfoValidClass') {
					if (data.basicItem.siteName != 'valid') {
						return false;
					} else if (data.basicItem.masterId != 'valid') {
						return false;
					} else if (data.basicItem.domain != 'valid') {
						return false;
					} else if (data.basicItem.url != 'valid') {
						return false;
					}

					//멀티컴퍼니 옵션 주문 있을경우 확인
					if (this.$utils.isOrderMultiCompany() && data.basicItem.doClusterId != 'valid') {
						return false;
					}

					for (var i in data.addonItem) {
						var val = data.addonItem[i];
						if (val.masterId != 'valid') {
							return false;
						}
					}
				} else {
					for (const [key, value] of Object.entries(data)) {
						if (value != 'valid') {
							return false;
						}
					}
				}
			}
			await this.saveAllInfo();
			return true;
		},
	},
};
</script>

<style scoped>
companyManagerInfo {
	margin-top: 10px !important;
}
</style>
