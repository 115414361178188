<template>
	<div class="wrap_row">
		<div class="row">
			<label class="form_tit">
				<span class="txt_large">담당자 정보</span>
			</label>
			<div class="form_data">
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.name == 'error' || companyManagerInfoValidClass.name == 'empty'
							? 'error'
							: companyManagerInfoValidClass.name == 'valid'
							? 'valid'
							: '',
					]"
				>
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.name"
							@input="
								[
									checkInputLength($event, 2, 64)
										? (companyManagerInfoValidClass.name = 'valid')
										: (companyManagerInfoValidClass.name = 'error'),
								];
								setIssuedBillManager(companyManagerInfo.issuedBillManagerSame);
							"
						/>
						<label for="target">담당자명</label>
					</div>
					<span v-if="companyManagerInfoValidClass.name == 'error'" class="sys_msg"
						>담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</span>
					<span v-if="companyManagerInfoValidClass.name == 'empty'" class="sys_msg"
						>담당자명은 필수 입력 항목입니다.
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.email == 'error' || companyManagerInfoValidClass.email == 'empty'
							? 'error'
							: companyManagerInfoValidClass.email == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.email"
							@keyup="
								[
									checkEmail(companyManagerInfo.email)
										? (companyManagerInfoValidClass.email = 'valid')
										: (companyManagerInfoValidClass.email = 'error'),
								];
								setIssuedBillManager(companyManagerInfo.issuedBillManagerSame);
							"
						/>
						<label for="target">이메일</label>
					</div>
					<span v-if="companyManagerInfoValidClass.email == 'error'" class="sys_msg"
						>이메일 형식이 올바르지 않습니다.
					</span>
					<span v-if="companyManagerInfoValidClass.email == 'empty'" class="sys_msg"
						>담당자 이메일은 필수 항목입니다.
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.phone == 'error' || companyManagerInfoValidClass.phone == 'empty'
							? 'error'
							: companyManagerInfoValidClass.phone == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							value="01099990000"
							v-model="companyManagerInfo.phone"
							@keyup="
								[
									checkPhone(companyManagerInfo.phone)
										? (companyManagerInfoValidClass.phone = 'valid')
										: (companyManagerInfoValidClass.phone = 'error'),
								]
							"
						/>
						<label for="target">휴대전화</label>
					</div>
					<span v-if="companyManagerInfoValidClass.phone == 'error'" class="sys_msg"
						>번호형식이 올바르지 않습니다. (-, +, 0~9)</span
					>
					<span v-if="companyManagerInfoValidClass.phone == 'empty'" class="sys_msg"
						>담당자 휴대전화는 필수 항목입니다.</span
					>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.tel == 'error' || companyManagerInfoValidClass.tel == 'empty'
							? 'error'
							: companyManagerInfoValidClass.tel == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							value="01099990000"
							v-model="companyManagerInfo.tel"
							@keyup="
								[
									checkTelFormat(companyManagerInfo.tel)
										? (companyManagerInfoValidClass.tel = 'valid')
										: (companyManagerInfoValidClass.tel = 'error'),
								]
							"
						/>
						<label for="target">유선전화</label>
					</div>
					<span v-if="companyManagerInfoValidClass.tel == 'error'" class="sys_msg"
						>번호형식이 올바르지 않습니다. (-, +, 0~9)</span
					>
					<span v-if="companyManagerInfoValidClass.tel == 'empty'" class="sys_msg"
						>담당자 유선전화는 필수 항목입니다.</span
					>
				</div>
				<div class="row">
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input class="" type="text" placeholder="." v-model="companyManagerInfo.grade" />
						<label for="target">직급</label>
					</div>
				</div>
				<div class="row">
					<div class="com_ck">
						<label
							>세금계산서 담당자가 담당자 정보와 동일
							<input
								type="checkbox"
								v-model="companyManagerInfo.issuedBillManagerSame"
								@change="setIssuedBillManager(companyManagerInfo.issuedBillManagerSame)" /><span
								class="checkmark"
							></span
						></label>
					</div>
					<span class="com_help">
						<span class="ic_set ic_info">
							<span class="tool_tip right">
								세금계산서 담당자 이메일로 세금계산서 관련 메일이 발송됩니다.<br />
								<i class="tail_right"></i
							></span>
						</span>
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.issuedBillName == 'error' ||
						companyManagerInfoValidClass.issuedBillName == 'empty'
							? 'error'
							: companyManagerInfoValidClass.issuedBillName == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.issuedBillName"
							@input="
								[
									checkInputLength($event, 2, 64)
										? (companyManagerInfoValidClass.issuedBillName = 'valid')
										: (companyManagerInfoValidClass.issuedBillName = 'error'),
								]
							"
						/>
						<label for="target">세금계산서 담당자명</label>
					</div>
					<span v-if="companyManagerInfoValidClass.issuedBillName == 'error'" class="sys_msg"
						>세금계산서 담당자명은 2자 이상, 64자 이하를 입력해야 합니다.
					</span>
					<span v-if="companyManagerInfoValidClass.issuedBillName == 'empty'" class="sys_msg"
						>세금계산서 담당자명은 필수 입력 항목입니다.
					</span>
				</div>
				<div
					class="row"
					:class="[
						companyManagerInfoValidClass.issuedBillEmail == 'error' ||
						companyManagerInfoValidClass.issuedBillEmail == 'empty'
							? 'error'
							: companyManagerInfoValidClass.issuedBillEmail == 'valid'
							? 'valid'
							: '',
					]"
				>
					<!--@type : normal-->
					<div class="com_ipt_floating">
						<input
							class="asterisk"
							type="text"
							placeholder="."
							v-model="companyManagerInfo.issuedBillEmail"
							@keyup="
								[
									checkEmail(companyManagerInfo.issuedBillEmail)
										? (companyManagerInfoValidClass.issuedBillEmail = 'valid')
										: (companyManagerInfoValidClass.issuedBillEmail = 'error'),
								]
							"
						/>
						<label for="target">세금계산서 담당자 이메일</label>
					</div>
					<span v-if="companyManagerInfoValidClass.issuedBillEmail == 'error'" class="sys_msg"
						>세금계산서 담당자 이메일 형식이 올바르지 않습니다.
					</span>
					<span v-if="companyManagerInfoValidClass.issuedBillEmail == 'empty'" class="sys_msg"
						>세금계산서 담당자 이메일은 필수 입력 항목입니다.
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { validation } from '@/components/apply/mixinsValidation.js';
export default {
	mixins: [validation],
	data() {
		const origin = this.$store.getters.getAllCompanyInfo.companyManagerInfo;
		return {
			companyManagerInfo: {
				issuedBillManagerSame: origin.issuedBillManagerSame ? origin.issuedBillManagerSame : false,
				name: origin.name ? origin.name : '', // 필수
				grade: origin.grade ? origin.grade : '',
				phone: origin.phone ? origin.phone : '', // 필수
				tel: origin.tel ? origin.tel : '', // 필수
				email: origin.email ? origin.email : '', // 필수
				issuedBillName: origin.issuedBillName ? origin.issuedBillName : '', // 필수
				issuedBillEmail: origin.issuedBillEmail ? origin.issuedBillEmail : '', // 필수
			},
			companyManagerInfoValidClass: {
				name: origin.name ? 'valid' : '',
				grade: 'valid',
				phone: origin.phone ? 'valid' : '',
				tel: origin.tel ? 'valid' : '',
				email: origin.email ? 'valid' : '',
				issuedBillName: origin.issuedBillName ? 'valid' : '',
				issuedBillEmail: origin.issuedBillEmail ? 'valid' : '',
			},
		};
	},
	computed: {},
	props: {
		gatherFlag: {
			type: Boolean,
			default: false,
		},
		saveFlag: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		async gatherFlag() {
			if (this.gatherFlag) {
				await this.checkValidInfo();
				await this.sendValidInfo();
			}
		},
		saveFlag() {
			if (this.saveFlag) {
				this.saveCompanyManagerInfo();
			}
		},
	},
	methods: {
		async checkValidInfo() {
			let isAllValid = true;
			for (const idx in Object.keys(this.companyManagerInfo)) {
				const key = Object.keys(this.companyManagerInfo)[idx];
				const value = this.companyManagerInfo[key];

				if (
					key == 'name' ||
					key == 'phone' ||
					key == 'tel' ||
					key == 'email' ||
					key == 'issuedBillName' ||
					key == 'issuedBillEmail'
				) {
					if (!value || value.length == 0) {
						this.companyManagerInfoValidClass[key] = 'empty';
					}
				}
				if (key !== 'issuedBillManagerSame' && this.companyManagerInfoValidClass[key] !== 'valid') {
					isAllValid = false;
				}
			}

			return isAllValid;
		},
		setIssuedBillManager(issuedBillManagerSame) {
			if (issuedBillManagerSame) {
				this.companyManagerInfo.issuedBillName = this.companyManagerInfo.name;
				this.companyManagerInfo.issuedBillEmail = this.companyManagerInfo.email;
				this.companyManagerInfoValidClass.issuedBillName = this.companyManagerInfoValidClass.name;
				this.companyManagerInfoValidClass.issuedBillEmail = this.companyManagerInfoValidClass.email;
			}
		},
		async sendValidInfo() {
			this.$emit('gatherData', {
				type: 'companyManagerInfo',
				companyManagerInfo: this.companyManagerInfo,
				companyManagerInfoValidClass: this.companyManagerInfoValidClass,
			});
		},
	},
};
</script>

<style></style>
